/**
 * 管理卡片内容渲染组件
 */
import Statistic from "@/components/Menu/ProCard/components/Statistic";
import Description from "@/components/Menu/ProCard/components/Description";
import DescriptionGroup from "@/components/Menu/ProCard/components/DescriptionGroup";
import Image from "@/components/Menu/ProCard/components/Image";
import ChartDashboard from "@/components/Menu/ProCard/components/ChartDashboard";
import Custom from "@/components/Menu/ProCard/components/Custom";
import Features from "@/components/Menu/ProCard/components/Features";

export const COMPONENT_MAP: any = {
  Default: () => <span style={{ color: "red" }}>渲染异常</span>,
  Statistic: (item: any) => <Statistic {...item} />,
  Description: (item: any) => <Description {...item} />,
  DescriptionGroup: (item: any) => <DescriptionGroup {...item} />,
  Image: (item: any) => <Image {...item} />,
  ChartDashboard: (item: any) => <ChartDashboard {...item} />,
  Custom: (item: any) => <Custom {...item} />,
  Features: (item: any) => <Features {...item} />,
};
