/**
 * 图片显示组件
 */
import "./index.less";

export interface ImageProps {
  localName?: string;
  remoteUrl?: string;
  wrapStyle?: object;
  // https://www.iconfont.cn/manage/index?spm=a313x.7781069.1998910419.23&manage_type=myprojects&projectId=3809704 - iconfont-name (Symbol模式)
  iconName?: string;
}

const classPrefix = "iot-component-image";

const Image: React.FC<ImageProps> = (props: ImageProps) => {
  const { localName, remoteUrl, wrapStyle = {}, iconName } = props;
  let imgSrc = "";
  if (localName) {
    imgSrc = require(`/public/images/${localName}`);
  }
  if (remoteUrl) {
    imgSrc = remoteUrl;
  }
  return (
    <div className={`${classPrefix}`} style={wrapStyle}>
      {/* imgSrc有配置时显示图片 */}
      {imgSrc && <img src={imgSrc} alt="image" />}
      {/* iconName有配置时显示iconfont图标 */}
      {iconName && (
        <svg className="icon svg-icon" aria-hidden="true">
          <use xlinkHref={`#${iconName}`}></use>
        </svg>
      )}
    </div>
  );
};

export default Image;
