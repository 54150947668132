/*
 * @Author: lenghao lenghao@kaihong.com
 * @Date: 2022-12-06 14:07:37
 * @LastEditors: OSS-LIRUNBING-0\dell 18312288722@139.com
 * @LastEditTime: 2023-02-13 16:58:21
 * @FilePath: \twin-view\src\api\index.ts
 * @Description: 请求api统一出口
 */

import base from './base';

const api = {
  ...base
};

export default api;
