/**
 * 特性组件，左边展示图标，右边展示特性文案
 */
// import { useState } from 'react';
// import { handleHttp } from '@/components/Menu/ProCard/utils/valueConfig';
import { DEFAULT_DATA } from "@/config";
import "./index.less";

export interface FeaturesProps {
  iconName?: string;
  value?: string;
  wrapStyle?: object;
  valueStyle?: object;
}

const FeaturesClassPrefix = "iot-component-features";

const Features = (props: FeaturesProps) => {
  const {
    iconName = "icon-texing",
    value = DEFAULT_DATA,
    wrapStyle = {},
    valueStyle = {},
  } = props;
  return (
    <div className={`${FeaturesClassPrefix}`} style={wrapStyle}>
      <div className={`${FeaturesClassPrefix}-icon`}>
        <span className={`icon iconfont ${iconName}`}></span>
      </div>
      <div className={`${FeaturesClassPrefix}-content`} style={valueStyle}>
        {value}
      </div>
    </div>
  );
};

export default Features;
