import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Tabs, List, Divider, Button, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import './index.less';

// import { BASE_SYSTEM_ENUM } from '@/utils/base';
import { BASE_SET_NOTIFICATIONS, BASE_SET_NOTIFICATION_OPEN } from '@/stores/base';
import api from '@/api';
import encodeQuery from '@/utils/encodeQuery';
import actions from '@/stores/globalstate';

const TWBaseNoticelPrefixCls = "tw-base-noticel";

function Noticel(props: any) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const navigateSubApp = (url: string, params: any) => {
    let truthUrl = url;
    if (params?.id) {
      truthUrl += `?id=${params?.id}`;
    }
    
    if (location.hash.includes("/khlinks")) {
      actions.setGlobalState({ hashPath: truthUrl });
    } else {
      navigate(`/#/khlinks${url}`, params);
    }
  }

  // 加载条数
  const loadMoreData = async() => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await api.baseNotices(encodeQuery({
      terms: { state: 'unread' },
      sorts: { notifyTime: 'desc' },
    }));

    setLoading(false);
    if(res.status === 200) {
      props.setNotifications(res.result);
    }
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  /**
   * 查看当前这条通知
   * @param params 当前这条数据，如果为null，则只跳转链接到通知页面
   */
  const handleClickNoticel = async(params: any) => {
    // 将当前这条通知状态改变成已读
    params && await api.baseChangeNoticeReadState(params);
    loadMoreData();

    navigateSubApp('/account/NotificationRecord', params || {});
    props.setNotificationsOpen(false);
  }

  /**
   * 将通知列表标记为已读
   */
  const handleClickClearNoticel =async () => {
    await api.baseClearNotices(props?.base?.notifications?.data?.map((item: { id: any; }) => item.id));
    loadMoreData();
    actions.setGlobalState({ notificationUpdate: {
        timestamp: +new Date(),
      }
    });
  }

  return (
    <Tabs
      defaultActiveKey="1"
      centered
      style={{width: 340}}
      items={['未读消息'].map((label, i) => {
  
        return {
          key: String(i + 1),
          label: (
            <span> {label} </span>
          ),
          children: (
            <div className={`${TWBaseNoticelPrefixCls}`} style={{height: props?.base?.notifications?.data?.length === 0 ? 240 : 400}}>
              <div
                id="base-scrollable"
                className={`${TWBaseNoticelPrefixCls}-list`}
              >
                <InfiniteScroll
                  dataLength={props?.base?.notifications?.data?.length}
                  next={loadMoreData}
                  hasMore={false}
                  loader={<Skeleton paragraph={{ rows: 1 }} active />}
                  // endMessage={<Divider plain>没有更多通知了</Divider>}
                  scrollableTarget="base-scrollable"
                >
                  <List
                    dataSource={props?.base?.notifications?.data || []}
                    renderItem={(item: any) => (
                      <List.Item
                        key={item.id}
                        onClick={ ()=> handleClickNoticel(item) }
                      >
                        <List.Item.Meta
                          title={item.topicName}
                          description={item.message}
                        />
                      </List.Item>
                    )}
                  />
                </InfiniteScroll>
              </div>
              <Divider style={{margin: 0}} />
              <div className={`${TWBaseNoticelPrefixCls}-tools`}>
                {/* <div>当前标记为已读</div> */}
                <Button
                  type="link"
                  onClick={ handleClickClearNoticel }
                  disabled={ props?.base?.notifications?.data?.length === 0 }
                >当前标记为已读</Button>
                <Divider type="vertical" style={{margin: 0}} />
                <Button type="link" onClick={ ()=> handleClickNoticel(null) }>查看更多</Button>
                {/* <div>查看更多</div> */}
              </div>
            </div>
          ),
        };
      })}
    />
  );
}

const mapStateToProps = (state: any) => {
  return state;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // 设置系统基本信息
    setNotifications: (data: any) => {
      dispatch({
        type: BASE_SET_NOTIFICATIONS,
        data
      });
    },
    // 设置告警消息弹窗状态
    setNotificationsOpen: (data: any) => {
      dispatch({
        type: BASE_SET_NOTIFICATION_OPEN,
        data,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Noticel);
