/**
 * 处理数据逻辑
 */
import { service } from "@/components/Menu";
import { DEFAULT_DATA } from "@/config";

export const handleHttp = (valueConfig: any, callback: any) => {
  const {
    url,
    params = {},
    resDataKey = "result",
    dependParams,
    resDataHandle,
  } = valueConfig;
  if (dependParams) {
    service
      .requestCommon(dependParams.url, dependParams.params)
      .then((resDepend: any) => {
        service.requestCommon(url, params).then((res: any) => {
          if (typeof resDataHandle === "function") {
            callback(resDataHandle(res, resDepend));
          }
        });
      });
  } else {
    service.requestCommon(url, params).then((res: any) => {
      let result: any = res;
      const resDataKeys = resDataKey.split(".");
      for (const dataKey of resDataKeys) {
        result = result[dataKey] ?? {};
      }
      if (result === res) {
        result = DEFAULT_DATA;
      } else if (typeof result === "object") {
        result = DEFAULT_DATA;
      }
      callback(result);
    });
  }
};
