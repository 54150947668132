import { BASE_SYSTEM_ENUM } from "@/utils/base";
import actions from "@/stores/globalstate";
import store from "@/stores";
import api from "@/api";
import { BASE_SET_AUTORIZE, BASE_MAIN_ROOT_ISLOADING, BASE_SET_USER_DETAIL } from "@/stores/base";
let currentHashPath = "";

const origin = location.origin;
// const MICRO_APPS: any = {
//   "khlinks": {
//     entry: 'http://dev.iot.kaihong.com',
//     activeRule: `/khlinks/`,
//   },
//   "atomic-center": {
//     entry: 'http://dev.iot.kaihong.com',
//     activeRule: `/atomic-center/`,
//   },
//   "dp-center": {
//     entry: 'http://dev.iot.kaihong.com',
//     activeRule: `/dms/dp-center/`,
//   }
// }

// const getActiveRule = (hash: string) => (location: any) => location.hash.startsWith(hash);
const debugEntry = "http://49.4.0.222";
const MICRO_APPS: any = {
  khlinks: {
    entry: debugEntry,
    // entry: 'http://localhost:9000',
    activeRule: "/#/khlinks", // getActiveRule('#/khlinks'),
    rule: "/kh_",
    mode: "hash", // 'hash'
  },
  "atomic-center": {
    entry: debugEntry,
    activeRule: `/atomic-center/`,
    mode: "history", // 'history'
  },
  "dp-center": {
    entry: debugEntry,
    activeRule: `/dp-center/`,
    mode: "history", // 'history'
  },
  "operation-management": {
    entry: debugEntry,
    activeRule: `/operation-management/`,
    mode: 'history', // 'history'
  },
  "ai-center": {
    entry: debugEntry,
    activeRule: `/ai-center/`,
    mode: "history", // 'history'
  },
  sro: {
    entry: debugEntry,
    activeRule: `/sro/`,
    mode: "history", // 'history'
  },
  "cascade-center": {
    entry: debugEntry,
    activeRule: `/cascade-center/`,
    mode: "history", // 'history'
  },
  "dataflow-center": {
    entry: debugEntry,
    activeRule: `/dataflow-center/`,
    mode: "history", // 'history'
  },
};

/**
 * 子应用名称列表
 * "khlinks-web": 超级设备管理平台
 * "atomic-center"：原子化服务平台
 * "dms"：数据管理服务
 */
export const subAppNames = Object.keys(MICRO_APPS);

// 特殊的子应用-超级终端管理平台（需要页数处理基座应用菜单跳转）
export const specialAppName = "khlinks";

// const normalAppNames = subAppNames.filter((name) => name !== specialAppName);

let env = "prod";
if (origin.includes("localhost")) {
  env = "dev";
}

const getEntry = (subAppName: string) => {
  if (subAppName === specialAppName) {
    return (env === "prod" ? origin : MICRO_APPS[subAppName]["entry"]) + "/sub-app/khlinks/";
  }
  return `${env === "prod" ? origin : MICRO_APPS[subAppName]["entry"]}/sub-app${MICRO_APPS[subAppName]["activeRule"]}`;
};

// 子应用注册初始化
export const getMicroApps = () => {
  // const dispatch = useDispatch();
  const result = subAppNames.map((subAppName) => {
    return {
      name: subAppName,
      entry: getEntry(subAppName),
      container: "#main-container",
      activeRule: `${BASE_SYSTEM_ENUM.APP_NAME}${MICRO_APPS[subAppName]["activeRule"]}`,
      loader: (loading: boolean) => {
        console.log(`${subAppName} 子应用loading`, +new Date(), loading);

        store.dispatch({
          type: BASE_MAIN_ROOT_ISLOADING,
          data: loading,
        });
      },
      props: {
        routerBase: BASE_SYSTEM_ENUM.APP_NAME + MICRO_APPS[subAppName]["activeRule"],
        utils: {
          /**
           * 更新用户基本信息
           */
          updateAuthorize: async () => {
            const res = await api.baseAutorizeMe();

            const { status, result } = res;
            if (status === 200) {
              // 成功
              store.dispatch({
                type: BASE_SET_AUTORIZE,
                data: result,
              });
            } else {
              store.dispatch({
                type: BASE_SET_AUTORIZE,
                data: null,
              });
            }
          },
          updateUserDetail: async () => {
            const res = await api.baseUserDetail();

            const { status, result } = res;
            if (status === 200) {
              // 成功
              store.dispatch({
                type: BASE_SET_USER_DETAIL,
                data: result,
              });
            } else {
              store.dispatch({
                type: BASE_SET_USER_DETAIL,
                data: {},
              });
            }
          },
        },
      },
    };
  });

  return result;
};

/**
 * 根据传入的url获取子应用完整路径
 * @param url
 */
export const getSubAppUrl = (url: string) => {
  let realAppUrl = BASE_SYSTEM_ENUM.APP_NAME;
  // subAppNames

  if (
    subAppNames.some(
      (app) =>
        MICRO_APPS[app]["mode"] === "history" &&
        (url.startsWith(MICRO_APPS[app]["activeRule"]) || url.startsWith(MICRO_APPS[app]["rule"]))
    )
  ) {
    // 为history模式的子应用
    realAppUrl += url;
  } else {
    // hash模式
    // MICRO_APPS[app]['mode']
    const result: any = subAppNames.filter(
      (app) =>
        MICRO_APPS[app]["mode"] === "hash" &&
        (url.startsWith(MICRO_APPS[app]["activeRule"]) || url.startsWith(MICRO_APPS[app]["rule"]))
    );
    if (result.length) {
      if (location.hash.includes(specialAppName)) {
        currentHashPath = url;
        actions.setGlobalState({ hashPath: url });
        return "";
      } else {
        realAppUrl += "/#/khlinks" + url; // `${result[0]['activeRule']}${url}`;
      }
      // realAppUrl += '#/khlinks' + url; // `${result[0]['activeRule']}${url}`;
      // actions.setGlobalState({ hashPath: url });
    } else {
      console.warn(`未知的应用菜单：${url}`);
    }
  }
  return realAppUrl;
};

window.addEventListener("hashchange", () => {
  /*
  slice(start,end):切片，返回新数组
      start——必需。规定从何处开始选取。
      end——可选。规定从何处结束选取。该参数是数组片断结束处的数组下标。如果没有指定该参数，那么切分的数组包含从 start 到数组结束的所有元素。
  * */
  const currentHash = location.hash.replace(`#/${specialAppName}`, "");
  if (currentHash !== currentHashPath) {
    currentHashPath = currentHash;
    actions.setGlobalState({ hashPath: currentHash });
  }
});
