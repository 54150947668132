/**
 * 左右布局的展示统计数值。
 */
import Description from '../Description';
import './index.less';

export interface DescriptionGroupProps {
  items?: any[];
  wrapStyle?: object;
  storeData?: any;
}

const DescriptionClassPrefix = 'iot-component-description-group';

const DescriptionGroup = (props: DescriptionGroupProps) => {
  const { items, wrapStyle, storeData = {} } = props;
  return (
    <div className={`${DescriptionClassPrefix}`} style={wrapStyle}>
      {items &&
        items.map((item, key) => {
          return <Description key={key} {...item} storeData={storeData} />;
        })}
    </div>
  );
};

export default DescriptionGroup;
